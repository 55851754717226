<template> 
  <v-sheet id="landing-features" color="transparent">
    <v-container class="py-4 py-lg-8" >
      <div class="text-center mb-4">
        <div class="text-uppercase font-weight-bold body-2 primary--text mb-2">Why Choose Us</div>
        <h2 class="text-h3 text-lg-h2">What Do We Offer</h2>
      </div>
      <v-row>
        <v-col
          v-for="(item, i) in features"
          :key="i"
          cols="12"
          sm="6"
          lg="4"
        >
          <v-card class="py-4 px-2">
            <v-responsive max-width="300" class="mx-auto text-center">
              <div class="mb-2">
                <v-icon class="text-h2" color="secondary">{{ item.icon }}</v-icon>
              </div>
              <div class="text-h6 mt-3">{{ item.title }}</div>
              <div class="font-weight-regular mt-2">{{ item.description }}</div>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      features: [{
        icon: 'mdi-clock-time-five-outline',
        title: '24/7 Access',
        description: 'Members are free to access our gym at anytime. Work out at your own schedule. Do not let regular business opening hours stop you.'
      }, {
        icon: 'mdi-face-woman-shimmer-outline',
        title: 'Ladies Zone',
        description: 'Our gym has a special designated area for the ladies, especially for those who prefer privacy and working out with a peace of mind. The muscular men won\'t bother you'
      }, {
        icon: 'mdi-cctv',
        title: 'Safety First',
        description: 'Our gym is under surveillance at all times to ensure our members can work out in a safe environment, whether it is early in the morning or late at night.'
      }, {
        icon: 'mdi-locker-multiple',
        title: 'Digital Lockers',
        description: 'Access our digital lockers with a single tap. No need to pay deposit or bring a key around during your workout session'
      }, {
        icon: 'mdi-account-supervisor-circle',
        title: 'Personal Trainer',
        description: 'You can hire personal trainers to provide you with guidance and motivate you along the journey.'
      }, {
        icon: 'mdi-chart-box-outline',
        title: 'Personalized Data (Coming Soon)',
        description: 'You will be able to view your workout sessions and progress on your personalized dashboard.'
      }]
    }
  }
}
</script>
