<template>
  <v-sheet class="hero9-bg" dark>
    <ToolbarOxy />
    <v-container id="landing-home" class="py-6 py-sm-12 text-center">
      <v-responsive max-width="800" class="mx-auto">
        <h1 class="text-h4 text-sm-h3 mb-4">Interested in a 24 hours free trial?</h1>
        <!-- <router-link to="/register"> -->
        <v-btn
          class="primary"
          x-large
        >Pay us a visit</v-btn>
        <!-- </router-link> -->
      </v-responsive>
    </v-container>
  </v-sheet>
</template>

<script>
import ToolbarOxy from '../toolbar/ToolbarOxy'

export default {
  components: {
    ToolbarOxy
  }
}
</script>

<style lang="scss" scoped>
.hero9-bg {
  background-image: url("~@/assets/images/fitness-girl.jpg");
  background-image: linear-gradient(
      to bottom right,
      rgba(16 57 92 / 96%),
      rgba(0 0 0 / 0.9)
    ),
    url("~@/assets/images/fitness-girl.jpg");
  background-size: cover;
  background-position: center;
}
</style>
