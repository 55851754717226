<template>
  <div>
    <v-container id="landing-fees" class="py-4 py-lg-8">
      <div style="width: 80px; height: 4px" class="mb-3 secondary" />
      <h2 class="text-h4 mb-3">Membership Fee</h2>

      <v-row class="mt-8">
        <v-col v-for="(plan, i) in plans_membership" :key="i" cols="12" lg="3">
          <v-card
            class="text-center text-body-1 px-2 py-4"
            :color="plan.color"
            :dark="plan.dark"
          >
            <div class="text-body-1 text-uppercase font-weight-black mb-6">{{ plan.title }}</div>
            <div class="d-flex justify-center">
              <div style="margin-top: 2px; margin-right: 1px;">RM</div>
              <div class="text-h3">{{ plan.price }}</div>
              <div style="margin-top: 1px; margin-left: 2px">00</div>
            </div>
            <div class="mt-6 text-body-1">
              <div v-for="(feat, j) in plan.features" :key="j">
                <span class="font-weight-black mr-1">{{ feat.value }}</span>
                <span class="font-weight-light">{{ feat.label }}</span>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="py-4 py-lg-8">
      <div style="width: 80px; height: 4px" class="mb-3 secondary" />
      <h2 class="text-h4 mb-3">Personal Trainer Fee</h2>

      <v-row class="mt-8">
        <v-col v-for="(plan, i) in plans_personalTrainer" :key="i" cols="12" lg="3">
          <v-card
            class="text-center text-body-1 px-2 py-4"
            :color="plan.color"
            :dark="plan.dark"
          >
            <div class="text-body-1 text-uppercase font-weight-black mb-6">{{ plan.title }}</div>
            <div class="d-flex justify-center">
              <div style="margin-top: 2px; margin-right: 1px;">RM</div>
              <div class="text-h3">{{ plan.price }}</div>
              <div style="margin-top: 1px; margin-left: 2px">00</div>
            </div>
            <div class="mt-6 text-body-1">
              <div v-for="(feat, j) in plan.features" :key="j">
                <span class="font-weight-black mr-1">{{ feat.value }}</span>
                <span class="font-weight-light">{{ feat.label }}</span>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plans_membership: [
        {
          highlight: false,
          color: '',
          dark: false,
          title: '3 Days',
          price: '40'
        },
        {
          highlight: false,
          color: '',
          dark: false,
          title: '7 Days',
          price: '70'
        },
        {
          highlight: false,
          color: '',
          dark: false,
          title: '30 Days',
          price: '110'
        },
        {
          highlight: false,
          color: '',
          dark: false,
          title: '90 Days',
          price: '280'
        }, 
        {
          highlight: false,
          color: '',
          dark: false,
          title: '180 Days',
          price: '530'
        },
        {
          highlight: false,
          color: 'secondary darken-2',
          dark: false,
          title: '365 Days',
          price: '1,000',
          features: [{
            value: 'Only RM 2.74',
            label: 'per day'
          }]
        }
      ],
      plans_personalTrainer: [
        {
          highlight: false,
          color: '',
          dark: false,
          title: '1 Class',
          price: '60'
        },
        {
          highlight: false,
          color: '',
          dark: false,
          title: '6 Classes',
          price: '340'
        },
        {
          highlight: false,
          color: '',
          dark: false,
          title: '12 Classes',
          price: '600'
        },
        {
          highlight: false,
          color: '',
          dark: false,
          title: '30 Classes',
          price: '1,450'
        }
      ]
    }
  }
}
</script>
