<template>
  <div>
    <HeroOxy></HeroOxy>
    <!-- <Logos2></Logos2> -->
    <FeatureOxy></FeatureOxy>
    <PricingOxy></PricingOxy>
    <CallToActionOxy/>
    <FooterOxy></FooterOxy>
  </div>
</template>

<script>
import HeroOxy from '@/components/ui/landing/hero/HeroOxy.vue'
import FeatureOxy from '@/components/ui/landing/feature/FeatureOxy.vue'
import PricingOxy from '@/components/ui/landing/pricing/PricingOxy.vue'
import CallToActionOxy from '@/components/ui/landing/cta/CallToActionOxy.vue'
import FooterOxy from '@/components/ui/landing/footer/FooterOxy.vue'

export default {
  components: {
    HeroOxy,
    FeatureOxy,
    PricingOxy,
    CallToActionOxy,
    FooterOxy
  }
}
</script>
